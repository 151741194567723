import React from 'react'
import { POKER, POKER_BOOKS } from '../../components/internal-links'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  HOMEPAGE_BREADCRUMB_TITLE,
  POKER_BREADCRUMB_TITLE,
  POKER_VARIANTS_BREADCRUMB_TITLE,
} from '../../utils/constnst.utils'
import { graphql, useStaticQuery } from 'gatsby'
import PokerLayout from '../../components/Poker/PokerLayout'

const PokerBooks = () => {
  const data = useStaticQuery(graphql`
    query {
      allGhostPost(filter: { slug: { eq: "poker-books" } }) {
        nodes {
          html
          title
          meta_title
          meta_description
        }
      }
    }
  `)

  const title = data.allGhostPost.nodes[0]?.title
  const breadcrumbs: Breadcrumb[] = [
    {
      title: HOMEPAGE_BREADCRUMB_TITLE,
      url: '/',
    },
    {
      title: POKER_VARIANTS_BREADCRUMB_TITLE,
      url: POKER,
    },
    {
      title: title,
      url: POKER_BOOKS,
    },
  ]

  const metaTitle = data.allGhostPost.nodes[0]?.meta_title
  const metaDescription = data.allGhostPost.nodes[0]?.meta_description

  const htmlContent = () => (
    <>
      <h1>{title}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: data.allGhostPost.nodes[0]?.html }}
      ></div>
    </>
  )

  return (
    <>
      <PokerLayout
        showFeatures={true}
        showOtherContent={false}
        showVideos={false}
        pageName="custom"
        breadcrumbs={breadcrumbs}
        showFullScreenBanner={true}
        pageSlug={{
          POKER_LINK: POKER_BOOKS,
        }}
        seoDetails={{
          title: metaTitle,
          description: metaDescription,
        }}
        englishContentHTML={htmlContent()}
      />
    </>
  )
}

export default PokerBooks
